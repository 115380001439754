import React from 'react'
import Page from '../templates/components/Page'
import HomePage from '../templates/home/HomeTemplate'

const Index = () => {
  return (
    <Page>
      <HomePage />
    </Page>
  )
}

export default Index

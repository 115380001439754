import React, { ReactChild, useEffect, useState } from 'react'
import type { Restaurant } from '../../globals'
import Card from '../components/Card'
import Button from '../../components/Button'
import Announcement from './components/Announcement'
import './HomeTemplate.scss'

const HomePage = () => {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([])

  useEffect(() => {
    fetch(process.env.GATSBY_API_URL + 'fetchDatabase')
      .then((res) => res.json())
      .then((result: Restaurant[]) => {
        setRestaurants(result)
      })
  }, [])

  let reviewsPreviewList: ReactChild[] = []
  if (restaurants == null || restaurants.length === 0) {
    for (var i = 0; i < 5; i++) reviewsPreviewList.push(<Card loading />)
  } else {
    reviewsPreviewList = restaurants
      .slice(0, 5)
      .map((restaurant, index) => (
        <Card data={restaurant} key={index} className={index === 4 ? 'cardLast' : ''} />
      ))
  }

  return (
    <>
      <Announcement />
      <div className="reviewPreviews">
        <div className="row subtitleRow">
          <div className="subtitle">Latest Reviews</div>
        </div>
        <div className="scrollSection">{reviewsPreviewList}</div>
        <div className="row moreSection">
          <Button text="See more" link="/reviews" />
        </div>
      </div>
    </>
  )
}

export default HomePage

import React, { useRef } from 'react'
import AtomixAnnouncementVideo from '../../../assets/videos/atomixAnnouncement.mp4'
import './Announcement.scss'

const Announcement = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const replayVideo = () => {
    const video = videoRef.current
    if (video === null) {
      return
    }

    video.currentTime = 0
    video.play()
  }

  return (
    <div className="heroContainer">
      <video ref={videoRef} autoPlay muted className="videoPlayer" onClick={replayVideo}>
        <source src={AtomixAnnouncementVideo} type="video/mp4" />
      </video>
    </div>
  )
}

export default Announcement

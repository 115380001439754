import React from 'react'
import Avatar from '../../components/Avatar'
import Link from '../../components/link'
import { getTierColor } from '../../utils/tiers.utils'
import { getAvatarSrc } from '../../utils/reviewers.utils'
import { getMonthDayYear } from '../../utils/dates.utils'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import './Card.scss'
import CardLoading from './CardLoading'

const Card = (props: any) => {
  if (props.loading) {
    return <CardLoading {...props} />
  }

  return (
    <Link
      className={props.className ? props.className + ' ' + 'cardContainer' : 'cardContainer'}
      to={`/reviews/${`${props.data.title}-${props.data.date.toString()}-${props.data.notionId}`}`}>
      <img className="cardImage" src={props.data.coverImage} alt="food" />
      <div className="cardRight">
        <div className="cardHeader">{getMonthDayYear(props.data.date)}</div>
        <div className="cardCenter">{props.data.reviewTitle}</div>
        <div className="cardFooter" style={{ marginTop: 'auto' }}>
          <div className="row">
            <div className="cardBadge" style={{ backgroundColor: getTierColor(props.data.tier) }}>
              {props.data.tier}
            </div>
            <div className="column">
              <span className="cardRestaurantName">{props.data.title}</span>
              <span className="cardRestaurantLocation">{props.data.location}</span>
            </div>
          </div>
          <div className="row">
            <span className="cardReviewer">{props.data.reviewer}</span>
            <Avatar
              small
              src={getAvatarSrc(props.data.reviewer)}
              alt={props.data.reviewer}
              fallback="RC"
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Card

import React from 'react'
import { ArrowUpRight } from 'react-feather'
import Link from './link'
import './Button.scss'

type ButtonProps = {
  text: string
  link: string
}

const Button = (props: ButtonProps) => {
  const { text, link } = props

  const buttonComponent = (
    <button type="button" className="button buttonPrimary">
      <div className="buttonIcon">
        <ArrowUpRight size={15} />
      </div>
      {text}
    </button>
  )

  return link !== undefined ? <Link to={link}>{buttonComponent}</Link> : buttonComponent
}

export default Button

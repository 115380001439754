import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './Card.scss'

const CardLoading = (props: any) => {
  return (
    <div className={'cardContainer'}>
      <div className="cardImage">
        <Skeleton width="100%" height="100%" />
      </div>
      <div className="cardRight">
        <div className="cardHeader">
          <Skeleton width="150px" />
        </div>
        <div className="cardCenter">
          <Skeleton count={3} />
        </div>
        <div className="cardFooter" style={{ marginTop: 'auto' }}>
          <div className="row">
            <Skeleton
              width="var(--space-5)"
              height="var(--space-5)"
              style={{ marginRight: 'var(--space-2)' }}
            />
            <div className="column">
              <span className="cardRestaurantName">
                <Skeleton width="150px" />
              </span>
              <span className="cardRestaurantLocation">
                <Skeleton width="100px" />
              </span>
            </div>
          </div>
          <div className="row">
            <span className="cardReviewer">
              <Skeleton width="150px" />
            </span>
            <Skeleton circle width="30px" height="30px" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLoading
